import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Shoulder Tap Productions" />
    <div>
      <StaticImage
        src="../images/st_logo.jpg"
        width={300}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Shoulder Tap"
        style={{ marginBottom: `1.45rem` }}
      />
    </div>
    <div>
      <h3>Site Coming Summer 2021</h3>
      <h6>Gonna do it all by hand</h6>
    </div>
  </Layout>
)

export default IndexPage
